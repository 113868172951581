import React from 'react';
import { Button } from '@mui/material';

const FilterComponent = ({ filters, selectedFilter, onSelectFilter }) => {
    const handleSelectFilter = (filterId) => {
        const newFilter = filterId === selectedFilter.id ? null : filterId;
        onSelectFilter(newFilter);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', border: '2px solid #6b6b6b', borderRadius: '.3rem' }}>
            {filters.map((filter) => {
                let backgroundColor = 'transparent';

                if (filter.id === selectedFilter.id) {
                    backgroundColor = '#8fd400';
                }

                return (
                    <Button
                        key={filter.id}
                        onClick={() => handleSelectFilter(filter.id)}
                        variant="contained"
                        disableElevation // Desativa a elevação
                        style={{
                            fontWeight: filter.id === selectedFilter ? 'bold' : 'normal',
                            margin: 0,
                            backgroundColor: backgroundColor,
                            color: filter.id === selectedFilter ? 'white' : 'black',
                            padding: '10px 20px',
                            fontSize: '1rem',
                            boxShadow: 'none', // Remove qualquer sombra adicional
                            '@media (max-width: 600px)': {
                                fontSize: '0.8rem',
                                padding: '8px 16px',
                            },
                        }}
                    >
                        {filter.name}
                    </Button>
                );
            })}
        </div>
    );
};

export default FilterComponent;
