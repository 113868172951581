import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { useSpring, animated } from 'react-spring';

const ProductComponent = ({ products, onSelectProduct, selectedCategory, selectedFilter }) => {
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

    useEffect(() => {
        const handleOrientationChange = () => {
            setIsPortrait(window.innerHeight > window.innerWidth);
        };

        window.addEventListener('resize', handleOrientationChange);

        return () => {
            window.removeEventListener('resize', handleOrientationChange);
        };
    }, []);

    const animatedProps = useSpring({
        from: { opacity: 0, transform: 'scale(0.9)' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: { tension: 280, friction: 20 },
    });

    return (
        <Grid container spacing={2} sx={{ padding: '0 25px 25px 25px' }}>
            <div>
                {/* <Typography variant="h6" style={{ fontWeight: 600, textAlign: 'center', fontSize: '1.2rem', color: '#333', }}>{selectedCategory.name} </Typography> */}
            </div>
            {products.map((product) => (
                <Grid
                    item
                    key={product.id}
                    xs={isPortrait ? 6 : 2.4}
                    sm={isPortrait ? 6 : 2.4}
                    md={isPortrait ? 4 : 2.4}
                    lg={isPortrait ? 4 : 2.4}
                    style={{ maxWidth: isPortrait ? '50%' : '20%', flexBasis: isPortrait ? '50%' : '20%' }}
                >
                    <animated.div style={animatedProps}>
                        <Card
                            onClick={() => onSelectProduct(product)}
                            sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                position: 'relative',
                                height: '100%',
                                boxShadow: 'none',
                                elevation: 0,
                            }}
                        >
                            {product.is_promotional && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '25px',
                                        left: '5px',
                                        transform: 'translateY(-50%)',
                                    }}
                                >
                                    <LocalOfferIcon sx={{ fontSize: '25px' }} />
                                </Box>
                            )}
                            {product.is_trending && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '20px',
                                        right: '20px',
                                        transform: 'translateY(-50%)',
                                        color: '#E8CA37',
                                        fontSize: '1rem',
                                    }}
                                >
                                    <StarOutlineIcon sx={{ fontSize: '25px' }} />
                                </Box>
                            )}
                            <CardContent
                                sx={{
                                    userSelect: 'none',
                                    minHeight: '120px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 'fit-content',
                                    boxShadow: 'none !important',
                                }}
                            >
                                <img
                                    src={`/img/${product.image_path}`}
                                    alt={product.name}
                                    style={{
                                        maxWidth: '100%',
                                        height: selectedFilter === 3 ? '200px' : 'auto',
                                        marginBottom: '8px',
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{ fontSize: 'clamp(16px, 2vw, 20px)' }}
                                >
                                    R${parseFloat(product.price10x).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </Typography>
                            </CardContent>
                        </Card>
                    </animated.div>
                </Grid>
            ))}
        </Grid>
    );
};

export default ProductComponent;
