import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ProductList.css';
import { ToastContainer, toast } from 'react-toastify';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddProductForm from './Admin/AddProductForm';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, TextField, FormControl, InputLabel, Select, MenuItem, List, ListItem, ListItemText, ListItemIcon, Typography, Grid } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt'; import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import nmaispeloImage from './logo1.png';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'; import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import ImageIcon from '@mui/icons-material/Image';
import FolderIcon from '@mui/icons-material/Folder';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles'; // Para estilizar componentes

const ProductList = () => {
  const [inputUsername, setInputUsername] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [products, setProducts] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [editingProductId, setEditingProductId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const [showPrices, setShowPrices] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [isBulkEditMode, setIsBulkEditMode] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [editCategoryOrder, setEditCategoryOrder] = useState('');
  const [bulkEditValue, setBulkEditValue] = useState({
    price6x: '',
    price10x: '',
    price_pix: '',
    desconto_maximo: ''
  });
  const [editFormData, setEditFormData] = useState({
    name: '',
    group: '',
    order: '',
    price10x: '',
    price6x: '',
    price_pix: '',
    category: '',
    image_path: '',
    desconto_maximo: '',
    valor_maximo: ''
  });
  const [newProductFormData, setNewProductFormData] = useState({
    name: '',
    group: '',
    order: '',
    price10x: '',
    price6x: '',
    price_pix: '',
    category: '',
    image_path: '',
    desconto_maximo: '',
    valor_maximo: ''
  });
  const [filters, setFilters] = useState([]);
  const [error, setError] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [editingFilter, setEditingFilter] = useState(null);
  const [newFilterName, setNewFilterName] = useState('');
  const [newFilterOrder, setNewFilterOrder] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [editCategoriesOpenDialog, setEditCategoriesOpenDialog] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState('');
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [addCategoryOpenDialog, setAddCategoryOpenDialog] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryFilter, setNewCategoryFilter] = useState(null);
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(null);
  const [confirmFilterDeleteOpen, setConfirmFilterDeleteOpen] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState(null);
  const [showCategories, setShowCategories] = useState(true);
  const [showProducts, setShowProducts] = useState(false);
  const [imageOpenDialog, imageSetOpenDialog] = useState(false);
  const [selectableImage, setSelectableImage] = useState(false)
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageUploadOpenDialog, setImageUploadOpenDialog] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openDeleteImageDialog, setOpenDeleteImageDialog] = useState(false)
  const [deleteImage, setDeleteImage] = useState(null);

  const handleLogin = (e) => {
    e.preventDefault()
    const validUsername = "vitta_admin";
    const validPassword = "jfu#r8u4f8_94he32!";

    if (inputUsername === validUsername && inputPassword === validPassword) {
      setIsAuthenticated(true);
      notifySuccess("Bem vindo vitta_admin!");
    } else {
      notifyError("Usuário ou senha Incorreta!");
    }
  };

  const AdminFormStyles = {
    body: {
      background: '#f3f3f3',
      fontFamily: 'Montserrat, sans-serif',
    },
  
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      minHeight: '100vh',
      backgroundColor: '#f4f7fc',
      padding: '20px',
      boxSizing: 'border-box',
    },
    formContainer: {
      backgroundColor: '#ffffff',
      padding: '40px',
      borderRadius: '10px',
      // boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
      width: '100%',
      maxWidth: '400px',
      textAlign: 'center',
      // Media query for small screens
      '@media (max-width: 600px)': {
        padding: '20px',
        borderRadius: '5px',
        width: '100%',
      },
    },
    title: {
      marginBottom: '30px',
      fontSize: '24px',
      fontWeight: '600',
      color: '#333333',
    },
    input: {
      width: '100%',
      padding: '12px',
      marginBottom: '20px',
      borderRadius: '5px',
      border: '1px solid #dddddd',
      fontSize: '16px',
      boxSizing: 'border-box',
    },
    button: {
      width: '100%',
      padding: '12px',
      borderRadius: '5px',
      backgroundColor: '#8EBE21',
      color: '#ffffff',
      fontSize: '16px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
    buttonHover: {
      backgroundColor: '#349237',
    },
    showPasswordButton: {
      position: 'absolute',
      right: '10px',
      top: '0',
      bottom: '0',
      margin: 'auto',
      height: '20px',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      color: '#1d1d1d',
      fontSize: '14px',
      padding: '0',
    },
  };
  

  const DeleteButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: '#ff6b6b',
    color: 'white',
    position: 'absolute',
    top: '8px',
    right: '8px',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    '&:hover': {
      backgroundColor: '#ff4d4d',
    },
  }));

  // Container da imagem com hover para exibir o botão de deletar
  const ImageContainer = styled('div')({
    position: 'relative',
    width: '100%',
    '&:hover': {
      '& .delete-button': {
        opacity: 1, // Mostra o botão de deletar ao passar o mouse
      },
    },
  });

  useEffect(() => {
    axios.get('https://orcamentodigital.app/api/products/')
      .then(response => {
        setProducts(response.data.sort((a, b) => a.id - b.id));
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the products!", error);
        setLoading(false);
      });

    axios.get('https://orcamentodigital.app/api/categories/')
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the categories!", error);
      });
  }, []);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await axios.get('https://orcamentodigital.app/api/filters/');
        setFilters(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchFilters();
  }, []);

  const getCategoryFilters = (categoryId) => {
    const category = categories.find(cat => cat.id === categoryId);
    if (!category) return [];
    const matchedFilters = filters.filter(filter => filter.id === category.filter);
    return matchedFilters.map(filter => filter.name);
  };

  const handleCloseCategoriesDialog = () => {
    setEditCategoriesOpenDialog(false);
    setEditCategoryId(null);
  };

  const getFilteredCategories = () => {
    const filterIds = filters.map(filter => filter.id);
    return categories.filter(cat => cat.filter && filterIds.includes(cat.filter));
  };

  const getFilteredCategoriesFilters = () => {
    const selectedFilterIds = selectedFilters;
    return categories.filter(cat => selectedFilterIds.includes(cat.filter));
  };

  const handleEditCategoriesDialogOpen = (categoryId, categoryName, categoryOrder) => {
    setEditCategoryId(categoryId);
    setEditCategoryName(categoryName);
    setEditCategoryOrder(categoryOrder);
    setEditCategoriesOpenDialog(true);
  };
  const handleEditCategoryNameChange = (event) => {
    setEditCategoryName(event.target.value);
  };


  const handleEditCategoryOrderChange = (event) => {
    setEditCategoryOrder(event.target.value);
  };

  const handleSaveCategory = () => {
    const categoryToUpdate = categories.find(cat => cat.id === editCategoryId);
    if (!categoryToUpdate) return;

    axios.put(`https://orcamentodigital.app/api/categories/${editCategoryId}/`, {
      name: editCategoryName,
      order: editCategoryOrder, // Inclua o novo campo order
      filter: categoryToUpdate.filter // Manter o filter existente
    })
      .then(response => {
        setCategories(categories.map(cat => cat.id === editCategoryId ? response.data : cat));
        notifySuccess("Categoria atualizada com sucesso!");
        // setEditCategoriesOpenDialog(false); // Fechar o diálogo
        setEditCategoryId(null); // Garantir que o ID da categoria seja resetado
      })
      .catch(error => {
        console.error("There was an error updating the category!", error);
      });
  };

  const handleDeleteCategory = (categoryId) => {
    setCategoryToDelete(categoryId);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = () => {
    axios.delete(`https://orcamentodigital.app/api/categories/${categoryToDelete}/`)
      .then(() => {
        setCategories(categories.filter(cat => cat.id !== categoryToDelete));
        notifySuccess("Categoria deletada com sucesso!");
        setConfirmDeleteOpen(false);
        setCategoryToDelete(null);
      })
      .catch(error => {
        notifyError("Erro ao deletar categoria!", error);
        console.error("There was an error deleting the category!", error);
      });
  };

  const handleOpenAddCategoryDialog = () => {
    setAddCategoryOpenDialog(true);
  };

  const handleCloseAddCategoryDialog = () => {
    setAddCategoryOpenDialog(false);
    setNewCategoryName('');
    setNewCategoryFilter(null);
  };

  const handleAddCategory = () => {
    const newCategory = {
      name: newCategoryName,
      filter: newCategoryFilter,
    };

    axios.post('https://orcamentodigital.app/api/categories/', newCategory)
      .then(response => {
        setCategories(prevCategories => [...prevCategories, response.data]);
        handleCloseAddCategoryDialog();
        notifySuccess("Categoria adicionada com sucesso!");
      })
      .catch(error => {
        notifyError("Erro ao adicionar categoria!", error);
        console.error("There was an error adding the category!", error);
      });
  };

  const handleCloseConfirmDelete = () => {
    setConfirmDeleteOpen(false);
    setCategoryToDelete(null);
  };

  const handleFilterClick = (filterId, event) => {
    event.stopPropagation();
    setSelectedFilters((prevSelectedFilters) => {
      setSelectedCategories([])
      if (prevSelectedFilters.includes(filterId)) {
        return prevSelectedFilters.filter(id => id !== filterId);
      } else {
        return [...prevSelectedFilters, filterId];
      }
    });
  };

  const handleCategoryFilterChange = (filterId) => {
    setSelectedFilterCategory(filterId);
  };

  const handleEditClick = (filter, event) => {
    event.stopPropagation();
    setEditingFilter(filter);
    setNewFilterName(filter.name);
    setNewFilterOrder(filter.order);
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.put(`https://orcamentodigital.app/api/filters/${editingFilter.id}/`,
        {
          name: newFilterName,
          order: newFilterOrder
        }
      );
      setFilters(filters.map(filter => (filter.id === editingFilter.id ? response.data : filter)));
      notifySuccess("Filtro atualizado com sucesso!");
      setEditingFilter(null);
    } catch (error) {
      notifyError("Erro ao atualizar filtro!", error);
      console.error("There was an error updating the filter!", error);
    }
  };

  const handleCancelClick = () => {
    setEditingFilter(null);
    setNewFilterName('');
  };

  const handleDeleteClick = (filterId, event) => {
    event.stopPropagation();
    const filter = filters.find(f => f.id === filterId);
    if (filter) {
      setFilterToDelete(filter);
      setConfirmFilterDeleteOpen(true);
    }
  };

  const handleConfirmFilterDelete = async () => {
    if (!filterToDelete) return;
    try {
      await axios.delete(`https://orcamentodigital.app/api/filters/${filterToDelete.id}/`);
      setFilters(filters.filter(filter => filter.id !== filterToDelete.id));
      notifySuccess("Filtro excluído com sucesso!");
      setEditingFilter(null);
    } catch (error) {
      notifyError("Erro ao excluir filtro!", error);
      console.error("There was an error deleting the filter!", error);
    }
    setConfirmFilterDeleteOpen(false);
    setFilterToDelete(null);
  };

  const handleToggleCategories = () => {
    setShowCategories(prev => !prev);
  };

  const handleToggleProducts = () => {
    setShowProducts(prev => !prev);
  };

  const handleCancelDelete = () => {
    setConfirmFilterDeleteOpen(false);
    setFilterToDelete(null);
  };

  const getRelatedCategories = () => {
    if (!filterToDelete) return [];
    return categories.filter(cat => cat.filter === filterToDelete.id);
  };

  const getProductsForCategory = (categoryId) => {
    return products.filter(product => product.category === categoryId);
  };

  const handleFilterClickOpen = () => {
    setFilterOpen(true);
  };

  const handleClose = () => {
    setFilterOpen(false);
    setNewFilterName('');
  };

  const handleCreateFilter = async () => {
    try {
      const response = await axios.post('https://orcamentodigital.app/api/filters/', { name: newFilterName });
      setFilters([...filters, response.data]);
      notifySuccess('Filtro criado com sucesso!');
      handleClose();
    } catch (error) {
      notifyError('Erro ao criar filtro!', error);
      console.error('There was an error creating the filter!', error);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const applyBulkEdit = () => {
    const updatedProducts = products.map((product) => {
      if (selectedProducts.includes(product.id)) {
        return {
          ...product,
          price6x: bulkEditValue.price6x || product.price6x,
          price10x: bulkEditValue.price10x || product.price10x,
          price_pix: bulkEditValue.price_pix || product.price_pix,
          desconto_maximo: bulkEditValue.desconto_maximo || product.desconto_maximo
        };
      }
      return product;
    });

    setProducts(updatedProducts);

    selectedProducts.forEach(productId => {
      const productToUpdate = updatedProducts.find(product => product.id === productId);
      axios.put(`https://orcamentodigital.app/api/products/${productId}/`, productToUpdate)
        .then(response => {
          notifySuccess(`Produto ${productId} atualizado com sucesso!`);
        })
        .catch(error => {
          console.error("Error updating product:", error);
          notifyError(`Erro ao atualizar produto ${productId}`);
        });
    });

    setIsBulkEditMode(false);
    setBulkEditValue({ price6x: '', price10x: '', price_pix: '', desconto_maximo: '' });
  };

  const notifySuccess = (message) => toast.success(message, { autoClose: 3000 });
  const notifyError = (message) => toast.error(message, { autoClose: 3000 });

  const handleDeleteProduct = (productId) => {
    axios.delete(`https://orcamentodigital.app/api/products/${productId}/`)
      .then(response => {
        setProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
        notifySuccess("Produto deletado com sucesso!");
      })
      .catch(error => {
        console.error("Error deleting product:", error);
        notifyError("Erro ao deletar produto!");
      });
  };

  const openDeleteDialog = (productId) => {
    setProductToDelete(productId);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteProduct = () => {
    handleDeleteProduct(productToDelete);
    setDeleteDialogOpen(false);
    setProductToDelete(null);
  };

  const handleEditProduct = (productId) => {
    const productToEdit = products.find(product => product.id === productId);

    const cleanImagePath = productToEdit.image_path.startsWith('img/')
      ? productToEdit.image_path.substring(4)
      : productToEdit.image_path;

    setEditFormData({
      name: productToEdit.name,
      group: productToEdit.group,
      order: productToEdit.order,
      price10x: productToEdit.price10x,
      price6x: productToEdit.price6x,
      price_pix: productToEdit.price_pix,
      category: productToEdit.category,
      image_path: cleanImagePath,
      desconto_maximo: productToEdit.desconto_maximo,
      valor_maximo: productToEdit.valor_maximo
    });
    setEditingProductId(productId);
  };

  const handleCancelEdit = () => {
    setEditFormData({
      name: '',
      group: '',
      order: '',
      price10x: '',
      price6x: '',
      price_pix: '',
      category: '',
      image_path: '',
      desconto_maximo: '',
      valor_maximo: ''
    });
    setEditingProductId(null);
  };

  const handleEditFormChange = (e) => {
    setEditFormData({ ...editFormData, [e.target.name]: e.target.value });
  };

  const handleSaveEdit = (productId) => {
    const updatedProduct = { ...editFormData };

    const toFloat = (value) => {
      if (typeof value === 'string') {
        return parseFloat(value.replace(',', '.'));
      }
      return parseFloat(value);
    };

    updatedProduct.price10x = toFloat(updatedProduct.price10x);
    updatedProduct.price6x = toFloat(updatedProduct.price6x);
    updatedProduct.price_pix = toFloat(updatedProduct.price_pix);
    updatedProduct.desconto_maximo = toFloat(updatedProduct.desconto_maximo);
    updatedProduct.valor_maximo = toFloat(updatedProduct.valor_maximo);

    if (updatedProduct.image_path.startsWith('img/')) {
      updatedProduct.image_path = updatedProduct.image_path.substring(4);
    }

    axios.put(`https://orcamentodigital.app/api/products/${productId}/`, updatedProduct)
      .then(response => {
        setProducts(prevProducts => prevProducts.map(product =>
          product.id === productId ? { ...product, ...updatedProduct } : product
        ));
        notifySuccess("Produto atualizado com sucesso!");
        handleCancelEdit();
      })
      .catch(error => {
        console.error("Erro ao atualizar produto:", error);
        notifyError("Erro ao atualizar produto!");
      });
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategories(prevCategories =>
      prevCategories.includes(categoryId)
        ? prevCategories.filter(cat => cat !== categoryId)
        : [...prevCategories, categoryId]
    );
  };

  const normalizeString = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategories([categoryId]);
  };

  const filteredProducts = products.filter(product => {
    const productCategories = categories.filter(category => category.products.some(p => p.id === product.id));
    const productFilterIds = filters.filter(filter => filter.categories.some(c => productCategories.map(cat => cat.id).includes(c.id))).map(f => f.id);

    return (selectedCategories.length === 0 || selectedCategories.includes(product.category)) &&
      (selectedFilters.length > 0 && productFilterIds.some(filterId => selectedFilters.includes(filterId))) &&
      normalizeString(product.name).includes(normalizeString(searchTerm));
  });

  const price10xTotal = filteredProducts.reduce((acc, product) => acc + (parseFloat(product.price10x) || 0), 0);
  const price6xTotal = filteredProducts.reduce((acc, product) => acc + (parseFloat(product.price6x) || 0), 0);
  const pricePixTotal = filteredProducts.reduce((acc, product) => acc + (parseFloat(product.price_pix) || 0), 0);
  const maxDiscount = filteredProducts.reduce((max, product) => Math.max(max, parseFloat(product.desconto_maximo) || 0), 0);

  const clearFilters = () => {
    setSelectedCategories([]);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleNewProductChange = (e) => {
    setNewProductFormData({ ...newProductFormData, [e.target.name]: e.target.value });
  };

  const handleAddProductFormSubmit = (event) => {
    event.preventDefault();
    handleAddProduct();
  };

  const handleAddProduct = () => {
    const newProduct = { ...newProductFormData };

    if (newProduct.image_path.startsWith('img/')) {
      newProduct.image_path = newProduct.image_path.substring(4);
    }

    if (
      !newProduct.name ||
      !newProduct.category ||
      !newProduct.group ||
      isNaN(newProduct.order) ||
      isNaN(newProduct.price10x) ||
      isNaN(newProduct.price6x) ||
      isNaN(newProduct.price_pix) ||
      isNaN(newProduct.desconto_maximo)
    ) {
      console.error("Por favor, preencha todos os campos corretamente.");
      return;
    }

    axios.post('https://orcamentodigital.app/api/products/', newProduct)
      .then(response => {
        setProducts(prevProducts => [...prevProducts, response.data]);
        notifySuccess("Produto adicionado com sucesso!");
        // Limpar o formulário depois de adicionar o produto
        setNewProductFormData({
          name: '',
          group: '',
          order: '',
          price10x: '',
          price6x: '',
          price_pix: '',
          category: '',
          image_path: '',
          desconto_maximo: '',
          valor_maximo: ''
        });
      })
      .catch(error => {
        console.error("Erro ao adicionar produto:", error.response.data);
        notifyError("Erro ao adicionar produto!");
      });
  };

  const handleMouseEnter = (productId) => {
    setHoveredProductId(productId);
  };

  const handleMouseLeave = () => {
    setHoveredProductId(null);
  };

  const handleProductSelect = (productId) => {
    setSelectedProducts(prevSelectedProducts => {
      if (prevSelectedProducts.includes(productId)) {
        return prevSelectedProducts.filter(id => id !== productId);
      } else {
        return [...prevSelectedProducts, productId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedProducts.length === filteredProducts.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(filteredProducts.map(product => product.id));
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  const handleDeleteSelected = () => {
    selectedProducts.forEach(productId => {
      handleDeleteProduct(productId);
    });
    setSelectedProducts([]);
  };

  const handleClickOpen = (product) => {
    setProductToDelete(product);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setProductToDelete(null);
  };

  const handleDeleteConfirm = () => {
    if (productToDelete) {
      handleDeleteSelected(productToDelete);
    }
    handleCloseDialog();
  };

  const toggleShowPrices = () => {
    setShowPrices(!showPrices);
  };

  const handleFolderClick = async (folder) => {
    try {
      const response = await axios.get(`https://orcamentodigital.app/api/images/${folder}/`);
      setSelectedImages(response.data.files);
    } catch (error) {
      console.error("Erro ao buscar as imagens:", error);
    }
  };

  const handleImageClick = (image) => {
    setNewProductFormData((prevData) => ({
      ...prevData,
      image_path: image,
    }));
    imageSetOpenDialog(false);
  };

  const handleEditingProductImageClick = (image) => {
    setEditFormData((prevData) => ({
      ...prevData,
      image_path: image,
    }));
    imageSetOpenDialog(false);
  }

  const handleFileChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('image', imageFile);

    fetch(`https://orcamentodigital.app/api/upload/${selectedFolder}/`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setImageUploadOpenDialog(false);
        notifySuccess("Upload de imagem bem sucedido!");
      })
      .catch((error) => {
        console.error('Erro no upload:', error);
        notifyError('Erro no upload:', error);
      });
  };

  // Função para abrir o modal de confirmação
  const handleDeleteImageClick = (image) => {
    setDeleteImage(image);
    setOpenDeleteImageDialog(true);
  };

  // Função para excluir a imagem
  const handleDeleteImageConfirm = async () => {
    try {
      await axios.delete(`https://orcamentodigital.app/api/images/delete/FLEX/${deleteImage.split('/').pop()}`);
      setSelectedImages((prevImages) => prevImages.filter((img) => img !== deleteImage));
      notifySuccess("Imagem deletada com sucesso!");
    } catch (error) {
      console.error("Erro ao deletar a imagem:", error);
      notifyError("Erro ao deletar a imagem.");
    } finally {
      setOpenDeleteImageDialog(false);
      setDeleteImage(null);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (!isAuthenticated) {
    return (
      <div style={AdminFormStyles.container}>
        <ToastContainer />
        <div style={AdminFormStyles.formContainer}>
          <img
            src={nmaispeloImage}
            alt="Admin Login"
            style={{
              display: 'block',
              width: '50%',
              margin: '2rem auto 0 auto',
              transition: 'width 0.5s ease-in-out',
            }}
          />
          <h2 style={AdminFormStyles.title}>Admin Login</h2>
          <form onSubmit={handleLogin}>
            <input
              type="text"
              value={inputUsername}
              onChange={(e) => setInputUsername(e.target.value)}
              placeholder="Digite o usuário"
              style={AdminFormStyles.input}
            />
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? 'text' : 'password'}
                value={inputPassword}
                onChange={(e) => setInputPassword(e.target.value)}
                placeholder="Digite a senha"
                style={AdminFormStyles.input}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                style={AdminFormStyles.showPasswordButton}
              >
                {showPassword ? 'Ocultar' : 'Mostrar'}
              </button>
            </div>
            <button
              type="submit"
              style={AdminFormStyles.button}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#349237')}
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#8EBE21')}
            >
              Entrar
            </button>
          </form>
        </div>
      </div>
    );
  }
  
  return (
    <div>
      {isAuthenticated && (
        <>
          <ToastContainer />
          <img
            src={nmaispeloImage}
            alt="Imagem antes dos filtros"
            style={{
              display: 'block',
              width: '20%',
              margin: '2rem auto 0 auto',
              transition: 'width 0.5s ease-in-out',
            }}
          />
          <h2 className="admin-panel-title">N+P Admin</h2>

          <div className="filter-toggle">
            {selectedFilters.length !== 0 && (
              <IconButton
                onClick={() => setShowAddForm(true)}
                className="add-product-button"
                style={{ backgroundColor: 'black', color: 'white' }}
              >
                <AddIcon />
              </IconButton>
            )}

            {selectedFilters.length > 0 && (
              <IconButton
                className="upload-button"
                onClick={() => {
                  imageSetOpenDialog(true)
                  setSelectableImage(false)

                }}
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  borderRadius: '50%',
                  fontSize: '1rem',
                }}
              >
                <ImageIcon />
              </IconButton>
            )}

            <Dialog open={imageOpenDialog}
              onClose={() => imageSetOpenDialog(false)}
              PaperProps={{
                style: {
                  width: '40vw', // Ajuste a largura conforme necessário
                  maxWidth: 'none', // Remove a largura máxima padrão
                },
              }}
            >
              <DialogTitle>{selectedImages.length === 0 ? "Escolha uma pasta" : "Imagens da pasta"}</DialogTitle>
              <DialogContent>
                {selectedImages.length === 0 ? (
                  // Seleção de pastas
                  <>
                    <List>
                      {['MASCULINO', 'FEMININO', 'FLEX'].map((folder) => (
                        <ListItem button key={folder} onClick={() => handleFolderClick(folder)}>
                          <ListItemIcon>
                            <FolderIcon style={{ color: '#4caf50' }} />
                          </ListItemIcon>
                          <ListItemText primary={folder} />
                        </ListItem>
                      ))}
                    </List>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                      <IconButton onClick={() => setImageUploadOpenDialog(true)} style={{ background: 'black', color: 'white', borderRadius: '.2rem', padding: '0 1.5rem' }}>
                        <p style={{ fontSize: '1rem', color: 'white' }}>Enviar imagem</p>
                        <CloudUploadIcon style={{ marginLeft: '.8rem' }} />
                      </IconButton>
                    </div>
                  </>
                ) : (
                  // Listagem de imagens com opções de deletar
                  <>
                    <IconButton
                      onClick={() => setSelectedImages([])}
                      style={{ marginBottom: '1rem', color: 'white', fontSize: '1.2rem', background: 'black', borderRadius: '.2rem', padding: '0.5rem 1.5rem' }}
                    >
                      Voltar
                    </IconButton>
                    <Grid container spacing={2}>
                      {selectedImages.map((image, index) => (
                        <Grid item xs={4} key={index}>
                          <ImageContainer>
                            <img
                              src={`https://orcamentodigital.app/${image}`}
                              alt={`Imagem ${index}`}
                              style={{ width: '80%', display: 'block', margin: '0 auto', cursor: showAddForm || editFormData ? 'pointer' : 'default' }}
                              onClick={() => {
                                if (showAddForm) {
                                  handleImageClick(image);
                                }
                                if (editFormData) {
                                  handleEditingProductImageClick(image);
                                }
                              }}
                            />
                            <Tooltip title="Excluir imagem" placement="top" arrow>
                              <DeleteButton
                                className="delete-button"
                                onClick={() => handleDeleteImageClick(image)}
                                size="small"
                              >
                                <DeleteIcon />
                              </DeleteButton>
                            </Tooltip>
                            <Typography variant="caption" display="block" align="center" style={{ marginTop: '0.5rem', fontWeight: 'bold', fontSize: '1rem' }}>
                              {image.split('/').pop()}
                            </Typography>
                          </ImageContainer>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </DialogContent>
            </Dialog>

            <Dialog open={openDeleteImageDialog} onClose={() => setOpenDeleteImageDialog(false)}>
              <DialogTitle>Confirmar exclusão</DialogTitle>
              <DialogContent>
                <Typography>Tem certeza de que deseja excluir esta imagem?</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDeleteImageDialog(false)} color="primary">
                  Cancelar
                </Button>
                <Button onClick={handleDeleteConfirm} color="secondary">
                  Excluir
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={openDeleteImageDialog} onClose={() => setOpenDeleteImageDialog(false)}>
              <DialogTitle>Confirmar exclusão</DialogTitle>
              <DialogContent>
                <Typography>Tem certeza de que deseja excluir esta imagem?</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDeleteImageDialog(false)} color="primary">
                  Cancelar
                </Button>
                <Button onClick={handleDeleteImageConfirm} color="error">
                  Excluir
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={imageUploadOpenDialog} onClose={() => setImageUploadOpenDialog(false)}>
              <DialogTitle>Upload de Imagem</DialogTitle>
              <DialogContent>
                <FormControl fullWidth>
                  <InputLabel>Escolha a Pasta</InputLabel>
                  <Select
                    value={selectedFolder}
                    onChange={(e) => setSelectedFolder(e.target.value)}
                  >
                    <MenuItem value="MASCULINO">MASCULINO</MenuItem>
                    <MenuItem value="FEMININO">FEMININO</MenuItem>
                    <MenuItem value="FLEX">FLEX</MenuItem>
                  </Select>
                </FormControl>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ marginTop: '1rem' }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setImageUploadOpenDialog(false)}>Cancelar</Button>
                <Button onClick={handleUpload} disabled={!imageFile || !selectedFolder}>Enviar</Button>
              </DialogActions>
            </Dialog>

            {showScrollButton && selectedProducts.length === 0 && (
              <IconButton
                onClick={() => setShowAddForm(true)}
                className="fixed-button"
                style={{ backgroundColor: 'black', color: 'white', position: 'fixed', bottom: '2rem', right: '2rem' }}
              >
                <AddIcon />
              </IconButton>
            )}

            <Dialog
              open={showAddForm}
              onClose={() => setShowAddForm(false)}
              PaperProps={{
                style: {
                  width: '600px',
                  height: '80vh',
                  maxWidth: 'none',
                },
              }}
            >
              <DialogTitle>Adicionar Produto</DialogTitle>
              <DialogContent>
                <AddProductForm
                  newProductFormData={newProductFormData}
                  handleNewProductChange={handleNewProductChange}
                  handleAddProductFormSubmit={handleAddProductFormSubmit}
                  setShowAddForm={setShowAddForm}
                  categories={categories}
                  filters={filters}
                  imageSetOpenDialog={imageSetOpenDialog}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowAddForm(false)}>Cancelar</Button>
              </DialogActions>
            </Dialog>

            {selectedFilters.length !== 0 && (

              <IconButton
                className="filter-button"
                onClick={() => setShowFilters(!showFilters)}
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  borderRadius: '.3rem',
                  fontSize: '1rem',
                }}
              >
                <FilterAltIcon />
                Filtrar
              </IconButton>

            )}

            {selectedFilters.length !== 0 && (
              <Button
                onClick={() => setEditCategoriesOpenDialog(true)}
                style={{
                  borderRadius: '4px',
                  border: 'none',
                  color: '#fff',
                  background: 'black',
                  fontSize: '1rem',
                  cursor: 'pointer',
                  textTransform: 'capitalize'
                }}
              >
                Editar Categorias
              </Button>
            )}

            {selectedCategories.length > 0 && (
              <IconButton
                className="clear-filters-button"
                onClick={clearFilters}
                style={{ backgroundColor: '#E74C3C', color: 'white' }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
          {selectedFilters.length > 0 && (

            <div className="search-input fixed-header">
              <SearchIcon
                style={{ marginLeft: '10px', marginRight: '10px', color: '#2C3E50' }}
              />
              <input
                type="text"
                placeholder="Buscar por nome do produto..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              {searchTerm && (
                <IconButton onClick={() => handleSearchChange({ target: { value: '' } })}>
                  <ClearIcon />
                </IconButton>
              )}
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px',
            }}
          >


            <div>
              {selectedFilters.length === 0 && (
                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <IconButton
                    onClick={handleFilterClickOpen}
                    style={{
                      background: 'black',
                      color: 'white',
                      borderRadius: '10px',
                      alignSelf: 'right',
                      fontSize: '1rem',
                      marginBottom: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AddIcon />
                    <span style={{ marginLeft: '8px' }}>Adicionar filtro</span>
                  </IconButton>
                </div>
              )}

              <Dialog open={filterOpen} onClose={handleClose}>
                <DialogTitle>Adicionar Novo Filtro</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Nome do Filtro"
                    type="text"
                    fullWidth
                    value={newFilterName}
                    onChange={(e) => setNewFilterName(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} style={{ color: "black" }}>
                    Cancelar
                  </Button>
                  <Button onClick={handleCreateFilter} style={{ color: "black" }}>
                    Salvar
                  </Button>
                </DialogActions>
              </Dialog>
              <div
                className="filters-container1"
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                  gap: '10px',
                  // background: 'yellow',
                  maxWidth: `calc(180px * ${filters.length} + 10px * (${filters.length - 1}))`,
                }}
              >
                {filters
                  .sort((a, b) => a.order - b.order) // Ordena os filtros pela propriedade `order`
                  .map((filter) => (
                    <div
                      key={filter.id}
                      className="filter-card"
                      style={{
                        position: 'relative',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        padding: '30px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.2s',
                        background: selectedFilters.includes(filter.id) ? '#def6dd' : 'white',
                      }}
                      onClick={(e) => handleFilterClick(filter.id, e)}
                      onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                      onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          top: '5px',
                          left: '5px',
                          width: '10px',
                          height: '10px',
                          padding: '8px',
                          borderRadius: '50%',
                          background: '#00000039',
                          color: 'black',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '16px',
                          fontWeight: 'bold',
                        }}
                      >
                        {filter.order}
                      </span>
                      <h3 style={{ margin: '0' }}>{filter.name}</h3>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditClick(filter, e);
                        }}
                        style={{
                          background: 'black',
                          color: 'white',
                          border: 'none',
                          borderRadius: '12px',
                          padding: '5px 20px',
                          cursor: 'pointer',
                          transition: 'background 0.3s',
                          fontSize: '14px',
                          marginTop: '10px',
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.background = '#333')}
                        onMouseLeave={(e) => (e.currentTarget.style.background = 'black')}
                      >
                        Editar
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            {editingFilter && (
              <Dialog open={true} onClose={handleCancelClick}>
                <DialogTitle>Editar Filtro</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Nome do Filtro"
                    type="text"
                    fullWidth
                    value={newFilterName}
                    onChange={(e) => setNewFilterName(e.target.value)}
                  />
                  <TextField
                    margin="dense"
                    label="Ordem do Filtro"
                    type="number"
                    fullWidth
                    value={newFilterOrder}
                    onChange={(e) => setNewFilterOrder(Number(e.target.value))}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelClick} style={{ color: "black" }}>
                    Cancelar
                  </Button>
                  <Button onClick={handleSaveClick} style={{ color: "black" }}>
                    Salvar
                  </Button>
                  <Button
                    onClick={(e) => handleDeleteClick(editingFilter.id, e)}
                    style={{
                      color: 'red',
                      border: 'none',
                      borderRadius: '12px',
                      padding: '5px 20px',
                      cursor: 'pointer',
                      transition: 'background 0.3s',
                    }}
                  >
                    Excluir
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            {confirmFilterDeleteOpen && (
              <Dialog open={confirmFilterDeleteOpen} onClose={handleCancelDelete}>
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                  <Typography variant="body1">
                    Tem certeza que deseja apagar o filtro <strong>{filterToDelete.name}</strong>? <br /> <span style={{ color: 'red', fontWeight: 'bold' }}>A exclusão do filtro ocasionará na exclusão das suas categorias e seus produtos relacionados!</span>
                  </Typography>
                  <div style={{ marginTop: '10px' }}>
                    <Button
                      onClick={handleToggleCategories}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#444',
                        border: 'none',
                        borderRadius: '12px',
                        padding: '5px 20px',
                        cursor: 'pointer',
                        transition: 'background 0.3s',
                        justifyContent: 'space-between',
                        fontWeight: 'bold'
                      }}
                    >
                      {showCategories ? 'Ocultar produtos a serem apagados' : 'Mostrar produtos a serem apagados'}
                      {showCategories ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                    {showCategories && (
                      <div>
                        <Typography variant="body2" style={{ marginTop: '10px' }}>
                          Total de produtos a serem apagados: <strong>{getRelatedCategories().reduce((acc, cat) => acc + getProductsForCategory(cat.id).length, 0)}</strong>
                        </Typography>
                        <ul style={{ marginTop: '10px', background: '#ccc', borderRadius: '.3rem', padding: '1rem', listStyle: 'none' }}>
                          {getRelatedCategories().length > 0 ? (
                            getRelatedCategories().map(cat => (
                              <li key={cat.id}>
                                <strong>{cat.name}</strong>
                                <ul style={{ marginTop: '5px' }}>
                                  {getProductsForCategory(cat.id).length > 0 ? (
                                    getProductsForCategory(cat.id).map(product => (
                                      <li key={product.id}>{product.name}</li>
                                    ))
                                  ) : (
                                    <li>Nenhum produto encontrado.</li>
                                  )}
                                </ul>
                              </li>
                            ))
                          ) : (
                            <li>Nenhuma categoria relacionada encontrada.</li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelDelete} style={{ color: "black" }}>
                    Cancelar
                  </Button>
                  <Button onClick={handleConfirmFilterDelete} style={{ color: "red" }}>
                    Excluir
                  </Button>
                </DialogActions>
              </Dialog>
            )}

          </div>
          <p style={{ textAlign: 'center' }}>
            {filteredProducts.length} produto(s) encontrado(s)
            <button onClick={toggleShowPrices} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#2C3E50', fontSize: '1rem' }}>
              <Tooltip title="Clique para ver a soma dos preços" arrow>
                <i className="fas fa-info-circle" style={{ cursor: 'pointer' }}></i>
              </Tooltip>
            </button>
          </p>
          {showPrices && (
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
              <div style={{ borderRadius: '8px', padding: '0 10px', marginBottom: '2rem', textAlign: 'center', width: '120px', background: '#fff', border: 'solid 1px #2C3E50' }}>
                <p style={{
                  paddingBottom: '5px',
                  borderBottom: 'solid 2px #2c3e5045',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: '#2C3E50',
                }}>Preço 10x</p>
                <p style={{
                  fontSize: '1rem',
                  color: '#27ae60',
                  fontWeight: 'bold'
                }}>R$ {price10xTotal.toFixed(2)}</p>
              </div>
              <div style={{ borderRadius: '8px', padding: '0 10px', marginBottom: '2rem', textAlign: 'center', width: '120px', background: '#fff', border: 'solid 1px #2C3E50' }}>
                <p style={{
                  paddingBottom: '5px',
                  borderBottom: 'solid 2px #2c3e5045',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: '#2C3E50',
                }}>Preço cred/deb</p>
                <p style={{
                  fontSize: '1rem',
                  color: '#27ae60',
                  fontWeight: 'bold'
                }}>R$ {price6xTotal.toFixed(2)}</p>
              </div>
              <div style={{ borderRadius: '8px', padding: '0 10px', marginBottom: '2rem', textAlign: 'center', width: '120px', background: '#fff', border: 'solid 1px #2C3E50' }}>
                <p style={{
                  paddingBottom: '5px',
                  borderBottom: 'solid 2px #2c3e5045',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: '#2C3E50',
                }}>Preço Pix</p>
                <p style={{
                  fontSize: '1rem',
                  color: '#27ae60',
                  fontWeight: 'bold'
                }}>R$ {pricePixTotal.toFixed(2)}</p>
              </div>
              <div style={{ borderRadius: '8px', padding: '0 10px', marginBottom: '2rem', textAlign: 'center', width: 'fit-content', background: '#fff', border: 'solid 1px #2C3E50' }}>
                <p style={{
                  paddingBottom: '5px',
                  borderBottom: 'solid 2px #2c3e5045',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: '#2C3E50',
                }}>Desconto Máximo</p>
                <p style={{
                  fontSize: '1rem',
                  color: '#27ae60',
                  fontWeight: 'bold'
                }}>R$ {maxDiscount.toFixed(2)}</p>
              </div>
            </div>
          )}

          {selectedFilters.length !== 0 && showFilters && (
            <div className="category-filter" style={{ display: 'flex', flexDirection: 'column' }}>
              {selectedFilters.map((filterId) => {
                const filteredCategories = categories.filter(
                  (cat) => cat.filter === filterId
                );

                if (filteredCategories.length > 0) {
                  const filterName = filters.find((filter) => filter.id === filterId).name;
                  return (
                    <>
                      <div key={filterId} className="filter-group">
                        {selectedFilters.length > 1 && (<h4 className="filter-title">{filterName}</h4>)}
                        <div className="filter-categories" style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {filteredCategories.map((cat) => (
                            <label key={cat.id} className="filter-checkbox">
                              <input
                                type="checkbox"
                                checked={selectedCategories.includes(cat.id)}
                                onChange={() => handleCategoryChange(cat.id)}
                              />
                              {cat.name}
                            </label>
                          ))}
                        </div>
                      </div>
                    </>
                  );
                }

                return null;
              })}
              <Button
                className="edit-categories-button"
                onClick={() => setEditCategoriesOpenDialog(true)}
                style={{
                  borderRadius: '4px',
                  border: 'none',
                  color: '#333',
                  fontSize: '1rem',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                EDITAR CATEGORIAS
              </Button>
            </div>
          )}

          <Dialog
            open={editCategoriesOpenDialog}
            onClose={handleCloseCategoriesDialog}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>Editar Categorias</DialogTitle>
            <DialogContent>
              <div style={{ display: 'flex', justifyContent: 'space-between', maxHeight: '45px' }}>
                <div style={{ marginBottom: '16px' }}>
                  <Button
                    variant={selectedFilterCategory === null ? 'contained' : 'outlined'}
                    onClick={() => handleCategoryFilterChange(null)}
                    style={{
                      marginRight: '8px',
                      backgroundColor: selectedFilterCategory === null ? 'black' : 'transparent',
                      color: selectedFilterCategory === null ? 'white' : 'inherit',
                      border: 'solid 1px black'
                    }}
                  >
                    Todos
                  </Button>
                  {filters.map((filter) => (
                    <Button
                      key={filter.id}
                      variant={filter.id === selectedFilterCategory ? 'contained' : 'outlined'}
                      onClick={() => handleCategoryFilterChange(filter.id)}
                      style={{
                        marginRight: '8px',
                        backgroundColor: filter.id === selectedFilterCategory ? 'black' : 'transparent',
                        color: filter.id === selectedFilterCategory ? 'white' : 'inherit',
                        border: 'solid 1px black'
                      }}
                    >
                      {filter.name}
                    </Button>
                  ))}
                </div>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleOpenAddCategoryDialog}
                  style={{ background: 'black' }}
                >
                  Adicionar Categoria
                </Button>
              </div>
              <Dialog
                open={addCategoryOpenDialog}
                onClose={handleCloseAddCategoryDialog}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle>Adicionar Nova Categoria</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Nome da Categoria"
                    type="text"
                    fullWidth
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                  />
                  <FormControl fullWidth style={{ marginTop: '16px' }}>
                    <InputLabel>Filtro</InputLabel>
                    <Select
                      value={newCategoryFilter}
                      onChange={(e) => setNewCategoryFilter(e.target.value)}
                    >
                      {filters.map((filter) => (
                        <MenuItem key={filter.id} value={filter.id}>
                          {filter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseAddCategoryDialog} style={{ color: 'black' }}>
                    Cancelar
                  </Button>
                  <Button onClick={handleAddCategory} style={{ color: 'black' }}>
                    Adicionar
                  </Button>
                </DialogActions>
              </Dialog>

              <List>
                {getFilteredCategories()
                  .filter(cat => selectedFilterCategory === null || cat.filter === selectedFilterCategory)
                  .sort((a, b) => a.order - b.order) // Ordenar de acordo com o campo order
                  .map((cat) => (
                    <ListItem key={cat.id}>
                      <ListItemIcon>
                        <Typography variant="body2" style={{ fontWeight: 'bold', marginRight: '16px' }}>
                          {cat.order}
                        </Typography>
                      </ListItemIcon>
                      <ListItemText primary={cat.name} />
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteCategory(cat.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => handleEditCategoriesDialogOpen(cat.id, cat.name, cat.order)}
                      >
                        <EditIcon />
                      </IconButton>
                    </ListItem>
                  ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCategoriesDialog} style={{ color: 'black' }}>
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={confirmDeleteOpen}
            onClose={handleCloseConfirmDelete}
          >
            <DialogTitle>Confirmar Exclusão</DialogTitle>
            <DialogContent>
              <p>Tem certeza de que deseja excluir esta categoria?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmDelete} style={{ color: "black" }}>
                Confirmar
              </Button>
              <Button onClick={handleCloseConfirmDelete}>
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={editCategoryId !== null}
            onClose={() => setEditCategoryId(null)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Editar</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nome da Categoria"
                type="text"
                fullWidth
                variant="standard"
                value={editCategoryName}
                onChange={handleEditCategoryNameChange}
              />
              <TextField
                margin="dense"
                id="order"
                label="Ordem da Categoria"
                type="number"
                fullWidth
                variant="standard"
                value={editCategoryOrder}
                onChange={handleEditCategoryOrderChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEditCategoryId(null)} style={{ color: "black" }}>
                Cancelar
              </Button>
              <Button onClick={handleSaveCategory} style={{ color: "black" }}>
                Salvar
              </Button>
            </DialogActions>
          </Dialog>

          {selectedProducts.length > 0 && (
            <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '1000', textAlign: 'center' }}>
              <div style={{ color: '#2C3E50', fontWeight: 'bold', marginBottom: '10px' }}>
                {selectedProducts.length} selecionado(s)
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <IconButton
                  onClick={handleSelectAll}
                  style={{ backgroundColor: '#2C3E50', color: 'white' }}
                >
                  <SelectAllIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleClickOpen(selectedProducts[0])}
                  style={{ backgroundColor: '#2C3E50', color: 'white', marginLeft: '10px' }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  onClick={() => setIsBulkEditMode(true)}
                  style={{ backgroundColor: '#2C3E50', color: 'white', marginLeft: '10px' }}
                >
                  <CalculateOutlinedIcon />
                </IconButton>
              </div>
            </div>
          )}

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
          >
            <DialogTitle>Confirmar Exclusão</DialogTitle>
            <DialogContent>
              <p>Você tem certeza que deseja excluir este produto?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} style={{ color: "black" }}>
                Cancelar
              </Button>
              <Button onClick={handleDeleteConfirm} style={{ color: "black" }}>
                Excluir
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={isBulkEditMode} onClose={() => setIsBulkEditMode(false)}>
            <DialogTitle>Editar Valores</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                label="Novo valor para price_pix"
                type="text"
                fullWidth
                value={bulkEditValue.price_pix}
                onChange={(e) => setBulkEditValue({ ...bulkEditValue, price_pix: e.target.value })}
              />
              <TextField
                margin="dense"
                label="Novo valor para price deb/cred"
                type="text"
                fullWidth
                value={bulkEditValue.price6x}
                onChange={(e) => setBulkEditValue({ ...bulkEditValue, price6x: e.target.value })}
              />
              <TextField
                margin="dense"
                label="Novo valor para price10x"
                type="text"
                fullWidth
                value={bulkEditValue.price10x}
                onChange={(e) => setBulkEditValue({ ...bulkEditValue, price10x: e.target.value })}
              />
              <TextField
                margin="dense"
                label="Novo valor para Desconto Máximo"
                type="text"
                fullWidth
                value={bulkEditValue.desconto_maximo}
                onChange={(e) => setBulkEditValue({ ...bulkEditValue, desconto_maximo: e.target.value })}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={applyBulkEdit}>
                Aplicar
              </Button>
              <Button onClick={() => setIsBulkEditMode(false)}>
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>

          <div className="product-grid">
            {filteredProducts.map((product) => (
              <div
                key={product.id}
                className="product-card"
                onMouseEnter={() => handleMouseEnter(product.id)}
                onMouseLeave={handleMouseLeave}
                style={{
                  backgroundColor: selectedProducts.includes(product.id) ? '#def6dd' : 'white',
                }}
              >
                {(hoveredProductId === product.id || selectedProducts.includes(product.id)) && (
                  <div className="checkbox-container" style={{ display: 'flex', alignItems: 'left' }}>
                    <input
                      type="checkbox"
                      checked={selectedProducts.includes(product.id)}
                      onChange={() => handleProductSelect(product.id)}
                      style={{ marginRight: '10px' }}
                    />
                  </div>
                )}
                <div className="product-card-icons">
                  <i className="fas fa-edit edit-icon" onClick={() => handleEditProduct(product.id)}></i>
                  <i className="fas fa-trash delete-icon" onClick={() => openDeleteDialog(product.id)}></i>
                </div>
                {editingProductId === product.id ? (
                  <Dialog open={true} onClose={handleCancelEdit}>
                    <DialogTitle>Editar Produto</DialogTitle>
                    <DialogContent>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSaveEdit(product.id);
                        }}
                      >
                        <TextField
                          label="Nome"
                          name="name"
                          value={editFormData.name}
                          onChange={handleEditFormChange}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Ordem"
                          name="order"
                          value={editFormData.order}
                          onChange={handleEditFormChange}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Preço 10x"
                          name="price10x"
                          value={editFormData.price10x.replace(/\./g, ',')}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setEditFormData(prevData => ({
                              ...prevData,
                              [name]: value.replace(/\./g, ',')
                            }));
                          }}
                          fullWidth
                          margin="normal"
                        />

                        <TextField
                          label="Preço Crédito/Débito"
                          name="price6x"
                          value={editFormData.price6x.replace(/\./g, ',')}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setEditFormData(prevData => ({
                              ...prevData,
                              [name]: value.replace(/\./g, ',')
                            }));
                          }}
                          fullWidth
                          margin="normal"
                        />

                        <TextField
                          label="Preço PIX"
                          name="price_pix"
                          value={editFormData.price_pix.replace(/\./g, ',')}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setEditFormData(prevData => ({
                              ...prevData,
                              [name]: value.replace(/\./g, ',')
                            }));
                          }}
                          fullWidth
                          margin="normal"
                        />

                        <TextField
                          label="Desconto Máximo"
                          name="desconto_maximo"
                          value={editFormData.desconto_maximo.replace(/\./g, ',')}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setEditFormData(prevData => ({
                              ...prevData,
                              [name]: value.replace(/\./g, ',')
                            }));
                          }}
                          fullWidth
                          margin="normal"
                        />

                        <TextField
                          label="Valor Máximo"
                          name="valor_maximo"
                          value={editFormData.valor_maximo.replace(/\./g, ',')}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setEditFormData(prevData => ({
                              ...prevData,
                              [name]: value.replace(/\./g, ',')
                            }));
                          }}
                          fullWidth
                          margin="normal"
                        />
                        <div style={{ display: 'flex' }}>
                          <TextField
                            label="Caminho da imagem"
                            name="image_path"
                            value={editFormData.image_path.replace('img/', '')}
                            onChange={handleEditFormChange}
                            fullWidth
                            margin="normal"
                          />

                          <IconButton onClick={() => imageSetOpenDialog(true)}>
                            <ImageIcon style={{ fontSize: '100%', height: '100%', fontSize: 'larger' }} />
                          </IconButton>
                        </div>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Grupo</InputLabel>
                          <Select
                            name="group"
                            value={editFormData.group}
                            onChange={handleEditFormChange}
                          >
                            {[1, 2, 3, 4, 'CB'].map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Filtro</InputLabel>
                          <Select
                            name="filter"
                            value={editFormData.filter}
                            onChange={handleEditFormChange}
                          >
                            {filters.map((filter) => (
                              <MenuItem key={filter.id} value={filter.id}>
                                {filter.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                          <InputLabel>Categoria</InputLabel>
                          <Select
                            name="category"
                            value={editFormData.category}
                            onChange={handleEditFormChange}
                          >
                            {categories
                              .filter((cat) => cat.filter === editFormData.filter)
                              .map((cat) => (
                                <MenuItem key={cat.id} value={cat.id}>
                                  {cat.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </form>
                    </DialogContent>
                    <DialogActions>
                      <Button type="submit" onClick={() => handleSaveEdit(product.id)} style={{ color: "black" }}>
                        Salvar
                      </Button>
                      <Button onClick={handleCancelEdit} style={{ color: "black" }}>
                        Cancelar
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : (
                  <div key={product.id}>
                    <p style={{ fontWeight: 'bold', color: '#2c3e50', fontSize: '1.2rem' }}>
                      {product.name}
                    </p>
                    <div style={{ marginBottom: '.8rem', display: 'flex', gap: '.3rem' }}>
                      {getCategoryFilters(product.category).map(filterName => (
                        <span
                          key={filterName}
                          style={{
                            display: 'inline-block',
                            backgroundColor: '#8fd400',
                            borderRadius: '12px',
                            padding: '2px 8px',
                            fontSize: '12px',
                            color: '#333',
                            fontWeight: 'bold',
                          }}
                        >
                          {filterName}
                        </span>
                      ))}

                      <span
                        style={{
                          display: 'inline-block',
                          backgroundColor: '#e0e0e0',
                          borderRadius: '12px',
                          padding: '2px 8px',
                          fontSize: '12px',
                          color: '#333',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          marginRight: '1px',
                        }}
                        onClick={() => handleCategoryClick(product.category)}
                      >
                        {categories.find((cat) => cat.id === product.category)?.name || 'Unknown'}
                      </span>
                    </div>
                    <p><strong>Grupo:</strong> {product.group}</p>
                    <p><strong>Ordem:</strong> {product.order}</p>
                    <p><strong>Preço 10x:</strong> {product.price10x}</p>
                    <p><strong>Créd/Deb:</strong> {product.price6x}</p>
                    <p><strong>Preço PIX:</strong> {product.price_pix}</p>
                    <p><strong>Desc. Max:</strong> {product.desconto_maximo}</p>
                    <p><strong>Valor Máximo:</strong> {product.valor_maximo}</p>
                    <p><strong>Caminho da Imagem:</strong> {product.image_path}</p>
                    <p><strong>Criado em:</strong> {new Date(product.created_on).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).replace(',', '')}</p>
                    <p><strong>Editado pela última vez:</strong> {new Date(product.last_edit_on).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).replace(',', '')}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
          {selectedFilters.length !== 0 && (
            <IconButton
              onClick={scrollToTop}
              style={{
                position: 'fixed',
                bottom: '20px',
                left: '20px',
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '50%',
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
          )}

          <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
            <DialogTitle>Confirmar Exclusão</DialogTitle>
            <DialogContent>Você tem certeza que deseja excluir este produto?</DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteDialogOpen(false)}>Cancelar</Button>
              <Button onClick={confirmDeleteProduct} color="error">
                Excluir
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default ProductList;