import React, { useState, useEffect } from 'react';
import POSComponent from './components/POSComponent';
import api from './apiConfig';
import appCss from './App.css'

const App = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const posPassword = process.env.POS_PASSWORD
  // alert(posPassword)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filtersResponse = await api.get('/api/filters/');
        const filters = filtersResponse.data.map(filter => ({
          id: filter.id,
          name: filter.name,
          order: filter.order,
          categories: filter.categories.map(category => ({
            id: category.id,
            name: category.name,
            order: category.order,
            products: category.products.sort((a, b) => {
              // Ordenar os produtos primeiro por is_promotional, depois por is_trending
              if (a.is_promotional !== b.is_promotional) {
                return a.is_promotional ? -1 : 1;
              } else if (a.is_trending !== b.is_trending) {
                return a.is_trending ? -1 : 1;
              } else {
                return a.order - b.order; // Se ambos is_promotional e is_trending forem iguais, ordene por order
              }
            }).map(product => ({
              id: product.id,
              name: product.name,
              group: product.group,
              desconto_maximo: product.desconto_maximo,
              valor_maximo: product.valor_maximo,
              image_path: product.image_path,
              order: product.order,
              price10x: product.price10x,
              price6x: product.price6x,
              price_pix: product.price_pix,
              description: product.description,
              is_promotional: product.is_promotional,
              is_trending: product.is_trending
            }))
          }))
            .sort((a, b) => a.order - b.order)
        }))
          .sort((a, b) => a.order - b.order);

        setData({ filters });
        console.log("Conteúdo de product.group:", filters[0].categories[0].products[0].group);

        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        {/* <div>Carregando...</div> */}
      </div>
    );
  }

  return (
    <div className="App" style={{background: 'white'}}>
      <POSComponent data={data} POS_PASSWORD={posPassword} />
    </div>
  );
};

export default App;
